<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-9-23 14:18:54 -->
<!-- @desc carouse.vue -->

<template>
    <div
        v-if="mounted"
        class="carouse-wrapper"
    >
        <img
            src="../img/notice.svg"
            alt=""
        >
        <div class="content-wrapper">
            <div class="bg bg-left" />
            <div
                v-if="carouse.rewardList && carouse.rewardList.length > 1"
                class="slide"
                :style="{
                    animationDuration: `${8 * (carouse.rewardList && carouse.rewardList.length || 0)}s`
                }"
            >
                <span
                    v-for="(item, index) in carouse.rewardList"
                    :key="index"
                >
                    {{ item }}
                </span>
                <span
                    v-for="(item, index) in carouse.rewardList"
                    :key="`cp-${index}`"
                >
                    {{ item }}
                </span>
            </div>

            <div
                v-else
                class="one"
            >
                <span>{{ carouse.rewardList && carouse.rewardList[0] }}</span>
            </div>
            <div class="bg bg-right" />
        </div>
    </div>
</template>

<script>
import prefetch from 'ssr-common/vue/mixin/server-prefetch';

import LotteryApi from 'easycash/common/resource/lottery';

export default {
    name: 'Carouse',

    mixins: [prefetch('carouse')],

    inject: ['activityId', 'getI18n'],

    data() {
        return {
            mounted: false
        };
    },

    mounted() {
        this.mounted = true;
    },

    methods: {
        async initFetch({header: headers}) {
            const {activityId} = this;
            const {data: {body}} = await LotteryApi.carouselRecentlyReults({
                headers,
                params: {activityId}
            })
                .catch(({data: {status: {code, detail}}}) => ({
                    data: {body: {rewardList: []}},
                    errCode: code,
                    errMsg: detail
                }));

            return {...body};
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.carouse-wrapper {
    display: flex;
    box-sizing: border-box;
    padding: 0.03rem 0.2rem;
    width: 100vw;
    background-color: #002a27;
    color: #fff;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;

    img {
        width: 0.14rem;
    }

    .content-wrapper {
        padding: 0 0.15rem;
        margin-left: 0.1rem;
        position: relative;
        overflow: hidden;
        width: 100%;
        box-sizing: border-box;
        height: 14px;

        span {
            line-height: 14px;
            font-size: 12px;
        }

        .slide {
            position: absolute;
            animation: slide 120s linear infinite;

            & > div {
                margin-right: 0.1rem;
            }
        }

        .one {
            text-align: center;
            position: absolute;
            animation: slide-one 7s linear infinite;
        }

        @keyframes slide {
            0% {
                transform: translateX(0);
            }

            100% {
                transform: translateX(-50%);
            }
        }

        @keyframes slide-one {
            0% {
                transform: translateX(100%);
            }

            100% {
                transform: translateX(-120%);
            }
        }

        .bg {
            position: absolute;
            top: 0;
            height: 100%;
            width: 0.3rem;
            z-index: 1;
        }

        .bg-left {
            background: linear-gradient(to right, rgba(0, 42, 39, 100%), rgba(0, 0, 0, 0%));
            left: 0;
        }

        .bg-right {
            background: linear-gradient(to left, rgba(0, 42, 39, 100%), rgba(0, 0, 0, 0%));
            right: 0;
        }
    }
}
</style>
