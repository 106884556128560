var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lottery" },
    [
      _c("div", { staticClass: "sudoku-wrapper" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.getI18n("lotteryTitleLeft")) +
              "\n            "
          ),
          _c("span", [_vm._v(_vm._s(_vm.remaining))]),
          _vm._v(
            "\n            " +
              _vm._s(_vm.getI18n("lotteryTitleRight")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sudoku" },
          _vm._l(_vm.sudokuList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "col",
                class: {
                  active: index === _vm.currIndex,
                  coupon: item && item.type === "COUPON",
                  start: index === 4,
                  disabled: _vm.btnDisableMap.draw,
                },
              },
              [
                index !== 4
                  ? _c("div", [
                      _c("img", { attrs: { src: item.imageUrl, alt: "" } }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.name) +
                          "\n                "
                      ),
                    ])
                  : _c("span", { on: { click: _vm.draw } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.getI18n("draw")) +
                          "\n                "
                      ),
                    ]),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("ec-overlay", { attrs: { visible: _vm.modalVisible } }, [
        _c("div", { staticClass: "modal-wrapper" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", [
              _c("h3", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.getI18n("modalTitle")) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "reward-list" },
                _vm._l(_vm.gotRewardList, function (item, index) {
                  return _c("span", { key: index }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(item) +
                        "\n                        "
                    ),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("button", { on: { click: _vm.handleConfirm } }, [
              _c("span", { staticClass: "button-content" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.getI18n("confirm")) +
                    "\n                    "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }