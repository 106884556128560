var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mounted
    ? _c("div", { staticClass: "carouse-wrapper" }, [
        _c("img", { attrs: { src: require("../img/notice.svg"), alt: "" } }),
        _vm._v(" "),
        _c("div", { staticClass: "content-wrapper" }, [
          _c("div", { staticClass: "bg bg-left" }),
          _vm._v(" "),
          _vm.carouse.rewardList && _vm.carouse.rewardList.length > 1
            ? _c(
                "div",
                {
                  staticClass: "slide",
                  style: {
                    animationDuration:
                      8 *
                        ((_vm.carouse.rewardList &&
                          _vm.carouse.rewardList.length) ||
                          0) +
                      "s",
                  },
                },
                [
                  _vm._l(_vm.carouse.rewardList, function (item, index) {
                    return _c("span", { key: index }, [
                      _vm._v(
                        "\n                " + _vm._s(item) + "\n            "
                      ),
                    ])
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.carouse.rewardList, function (item, index) {
                    return _c("span", { key: "cp-" + index }, [
                      _vm._v(
                        "\n                " + _vm._s(item) + "\n            "
                      ),
                    ])
                  }),
                ],
                2
              )
            : _c("div", { staticClass: "one" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.carouse.rewardList && _vm.carouse.rewardList[0])
                  ),
                ]),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "bg bg-right" }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }