<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-9-13 17:28:19 -->
<!-- @desc index.vue -->

<template>
    <div class="sudoku-lottery">
        <lottery-carouse class="carouse" />

        <lottery
            v-if="lotteryInfo.rewardList && lotteryInfo.rewardList.length"
            ref="lottery"
            :reward-list="lotteryInfo.rewardList"
            :remaining="sudokuLottery.remaining"
            :err-code="sudokuLottery.errCode"
            @lottery="handleLottery"
        />

        <div
            class="go-record"
            @click="routeDetail"
        >
            {{ getI18n('goRecordText') }}
        </div>

        <div class="rule-wrapper">
            <yqg-md-preview
                class="rule-md text"
                :md-text="sudokuLottery.description"
            />
        </div>
    </div>
</template>

<script>
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import prefetch from 'ssr-common/vue/mixin/server-prefetch';

import LotteryApi from 'easycash/common/resource/lottery';

import LotteryCarouse from './component/carouse';
import Lottery from './component/lottery';

export default {
    name: 'SudokuLottery',

    components: {
        Lottery,
        LotteryCarouse
    },

    mixins: [prefetch('sudokuLottery')],

    title() {
        return this.getI18n('title');
    },

    provide() {
        const {activityId, getI18n} = this;

        return {activityId, getI18n};
    },

    computed: {
        activityId() {
            return this.$route.params.activityId;
        },

        lotteryInfo() {
            return this.sudokuLottery.rule || {};
        }
    },

    methods: {
        getI18n(key, params) {
            return this.$t(`webview.activity.sudokuLottery.${key}`, params);
        },

        async initFetch({header: headers}) {
            const [config, chance] = await Promise.all([
                this.fetchConfig(headers),
                this.fetchChance(headers)
            ]);

            return {
                ...config,
                ...chance
            };
        },

        async fetchConfig(headers) {
            const {activityId} = this;
            const {data: {body}} = await LotteryApi.getLotteryConfig({
                headers,
                params: {activityId}
            })
                .catch(({data: {status: {code, detail}}}) => ({
                    data: {body: {rule: {}}},
                    errCode: code,
                    errMsg: detail
                }));

            return {...body};
        },

        async fetchChance(headers) {
            const {activityId} = this;
            const {data: {body}, errCode} = await LotteryApi.chance({
                headers,
                params: {activityId}
            })
                .catch(({data: {status: {code, detail}}}) => ({
                    data: {body},
                    errCode: code,
                    errMsg: detail
                }));

            return {...body, errCode};
        },

        routeDetail() {
            const {activityId, sudokuLottery: {errCode}} = this;
            if (errCode === 4001) {
                WebviewInterface.callHandler('showLoginFlow', '', () => {
                    window.location.reload();
                });

                return;
            }

            if (this.$refs.lottery?.isRuning) return;
            const {href} = this.$router.resolve({
                name: 'ActivityRecord',
                params: {activityId}
            });

            WebviewInterface.openWebview(href);
        },

        async handleLottery() {
            await this.requestData({}, true);
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
div.sudoku-lottery {
    padding: 0;
    background-color: #013728;
    position: relative;

    .carouse {
        position: fixed;
        top: 0;
        left: 0;
    }

    .go-record {
        padding: 0.3rem 0;
        text-align: center;
        font-size: 14px;
        font-family: "Helvetica-Bold", Helvetica;
        font-weight: bold;
        color: #ffc3b6;
        line-height: 17px;
        position: relative;

        &::after {
            content: " ";
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 3px solid #ffc3b6;
            border-left: transparent;
            border-bottom: transparent;
            transform: rotate(45deg);
            margin-left: 5px;
        }
    }

    .rule-wrapper {
        padding: 0 0.2rem 0.63rem;
        color: #00360a;
        font-size: 14px;

        .rule-title {
            color: #fff;
        }

        .rule-md {
            padding: 0.3rem 0.25rem;
            border-radius: 15px;
            background: linear-gradient(312deg, #fff 0%, #d3ffdb 100%);
            overflow: hidden;

            &::v-deep {
                h3 {
                    margin: 0.1rem 0 0;
                }

                p {
                    margin: 0;
                }

                li {
                    list-style-type: decimal;
                }

                table {
                    margin: 0.1rem 0;
                    border-collapse: separate;
                    border-spacing: 0;
                    font-size: 10px;
                    width: 100%;
                    border: 1px solid #c4e5e7;
                    border-radius: 5px;

                    th {
                        border: 1px solid #c4e5e7;
                        padding: 0.05rem 0 0.05rem 0.12rem;
                        border-width: 0 1px 1px 0;
                        background-color: #b3f0ff;

                        &:last-child {
                            border-right: 0;
                        }
                    }

                    tr {
                        background-color: #e9ffff;

                        td {
                            padding: 0.05rem 0 0.05rem 0.12rem;
                            border: 1px solid #c4e5e7;
                            border-width: 0 1px 1px 0;

                            &:last-child {
                                border-right: 0;
                            }
                        }

                        &:last-child {
                            td {
                                border-bottom: 0;
                                border-radius: 5px;
                            }
                        }

                        &:nth-child(odd) {
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
}
</style>
