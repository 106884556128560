var SignMap = {
    id: {
        thousands: '.',
        point: ','
    },
    en: {
        thousands: ',',
        point: '.'
    }
};
export var numberToString = function (amount, country) {
    if (country === void 0) { country = 'id'; }
    if (amount === undefined)
        return 0;
    var numArr = String(amount).split('');
    var pointIndex = numArr.findIndex(function (val) { return val === '.'; });
    if (pointIndex < 0)
        pointIndex = numArr.length;
    var intArr = numArr.slice(0, pointIndex);
    var floatArr = numArr.slice(pointIndex + 1);
    var flag = 0;
    var sign = SignMap[country].thousands;
    var resArr = intArr.reduceRight(function (acc, val) {
        if (flag === 3) {
            acc.unshift(sign);
            flag = 0;
        }
        acc.unshift(val);
        flag += 1;
        return acc;
    }, []);
    return "".concat(resArr.join('')).concat(floatArr.length ? SignMap[country].point : '').concat(floatArr.join(''));
};
export var stringToNumber = function (str) {
    if (typeof str !== 'string')
        return 0;
    var num = Number(str.replace(/\./g, '').replace(',', '.'));
    if (Number.isNaN(num))
        return 0;
    return num;
};
