var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sudoku-lottery" },
    [
      _c("lottery-carouse", { staticClass: "carouse" }),
      _vm._v(" "),
      _vm.lotteryInfo.rewardList && _vm.lotteryInfo.rewardList.length
        ? _c("lottery", {
            ref: "lottery",
            attrs: {
              "reward-list": _vm.lotteryInfo.rewardList,
              remaining: _vm.sudokuLottery.remaining,
              "err-code": _vm.sudokuLottery.errCode,
            },
            on: { lottery: _vm.handleLottery },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "go-record", on: { click: _vm.routeDetail } }, [
        _vm._v("\n        " + _vm._s(_vm.getI18n("goRecordText")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rule-wrapper" },
        [
          _c("yqg-md-preview", {
            staticClass: "rule-md text",
            attrs: { "md-text": _vm.sudokuLottery.description },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }