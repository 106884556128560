<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-9-13 17:32:49 -->
<!-- @desc lottery.vue -->

<template>
    <div class="lottery">
        <div class="sudoku-wrapper">
            <div class="title">
                {{ getI18n('lotteryTitleLeft') }}
                <span>{{ remaining }}</span>
                {{ getI18n('lotteryTitleRight') }}
            </div>
            <div class="sudoku">
                <div
                    v-for="(item, index) in sudokuList"
                    :key="index"
                    :class="{
                        active: index === currIndex,
                        coupon: item && item.type === 'COUPON',
                        start: index === 4,
                        disabled: btnDisableMap.draw
                    }"
                    class="col"
                >
                    <div v-if="index !== 4">
                        <img
                            :src="item.imageUrl"
                            alt=""
                        >
                        {{ item.name }}
                    </div>

                    <span
                        v-else
                        @click="draw"
                    >
                        {{ getI18n('draw') }}
                    </span>
                </div>
            </div>
        </div>

        <ec-overlay :visible="modalVisible">
            <div class="modal-wrapper">
                <div class="modal-content">
                    <div>
                        <h3>
                            {{ getI18n('modalTitle') }}
                        </h3>

                        <div class="reward-list">
                            <span
                                v-for="(item, index) in gotRewardList"
                                :key="index"
                            >
                                {{ item }}
                            </span>
                        </div>
                    </div>

                    <button @click="handleConfirm">
                        <span class="button-content">
                            {{ getI18n('confirm') }}
                        </span>
                    </button>
                </div>
            </div>
        </ec-overlay>
    </div>
</template>

<script>
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

import EcOverlay from 'easycash/common/component/ec-overlay';
import Lottery from 'easycash/common/resource/lottery';
import {numberToString} from 'easycash/common/util/format-amount';

import modalBg from '../img/modal.png';

export default {
    name: 'Lottery',

    components: {
        EcOverlay
    },

    inject: ['activityId', 'getI18n'],

    props: {
        rewardList: {
            type: Array,
            required: true
        },

        remaining: {
            type: Number,
            default: 0
        },

        errCode: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            indexList: [0, 1, 2, 5, 8, 7, 6, 3],
            currIndex: 1,
            indexPointer: 0,
            maxSpeed: 16,
            minSpeed: 4,
            speedStep: 4,
            stopCount: 8,
            resIndex: 8,
            speed: 0,
            stepCount: 0,
            res: null,
            gotRewardList: [],
            modalVisible: false,
            isRuning: false
        };
    },

    computed: {
        btnDisableMap() {
            const {remaining} = this;

            return {
                draw: remaining < 1
            };
        },

        sudokuList() {
            const {rewardList, indexList} = this;
            if (Array.isArray(rewardList)) {
                return rewardList.reduce((acc, {index, ...item}) => {
                    acc[indexList[index - 1]] = item;

                    return acc;
                }, []);
            }

            return [];
        }
    },

    mounted() {
        const img = new Image();
        img.src = modalBg;
    },

    methods: {
        numberToString,

        async draw() {
            if (this.login()) return;
            if (this.isRuning) return;
            this.isRuning = true;

            const {minSpeed} = this;
            const res = await this.drawLottery(1);
            const round = 4;
            if (!res) return;
            this.res = res;
            this.currIndex = 1;
            this.indexPointer = 1;
            this.speed = minSpeed;
            this.stepCount = 8 * round + res - 1 - this.currIndex;
            this.run(true, true);
        },

        async duplicateDraw(times) {
            if (this.login()) return;

            const {maxSpeed} = this;
            const res = await this.drawLottery(times);
            if (!res) return;
            const round = 6;
            this.currIndex = 1;
            this.indexPointer = 1;
            this.speed = maxSpeed;
            this.stepCount = 8 * round;
            this.run();
        },

        run(speedControl, stop) {
            const {indexList, indexPointer, speed, stepCount} = this;
            this.currIndex = indexList[indexPointer % indexList.length];
            this.indexPointer += 1;
            this.stepCount -= 1;
            if (speedControl) this.speedControl();
            if (stepCount > 0) {
                setTimeout(() => {
                    this.run(speedControl, stop);
                }, 1000 / speed);
            } else {
                if (!stop) this.currIndex = -1;
                this.$emit('end', this.res);
                this.modalVisible = true;
            }
        },

        speedControl() {
            const {stepCount, minSpeed, maxSpeed, speed, speedStep, stopCount} = this;
            if (stepCount > stopCount && speed < maxSpeed) {
                this.speed = Math.min((speed + speedStep), maxSpeed);

                return;
            }

            if (stepCount < stopCount && speed > minSpeed) {
                this.speed = Math.max((speed - speedStep), minSpeed);
            }
        },

        async drawLottery(times) {
            const params = {
                activityId: this.activityId,
                times
            };
            const {data: {body: {
                rewardIndex,
                reward
            }, error}} = await Lottery.sudokuDrawLottery({params})
                .catch(() => ({data: {
                    body: {},
                    error: true
                }}));

            if (error) return false;

            this.gotRewardList = [reward];

            if (times === 1) {
                return rewardIndex;
            }

            return true;
        },

        handleConfirm() {
            this.modalVisible = false;
            this.isRuning = false;
            this.$emit('lottery');
        },

        login() {
            if (this.errCode === 4001) {
                WebviewInterface.callHandler('showLoginFlow', '', () => {
                    window.location.reload();
                });

                return true;
            }

            return false;
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.lottery {
    button,
    .button-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-color: transparent;
        outline: none;
        border: none;
        color: #b30b00;
    }

    .sudoku-wrapper {
        position: relative;
        height: 5.21rem;
        background-image: url("../img/header.png");
        background-size: cover;

        .title {
            position: absolute;
            top: 2.29rem;
            left: 50%;
            transform: translateX(-50%);
            color: #fff;
            font-size: 12px;
            font-family: Helvetica;
            line-height: 1.2;
        }

        .sudoku {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            position: absolute;
            top: 2.71rem;
            left: 0.53rem;
            width: 2.7rem;

            .col {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0.06rem;

                // padding-top: .05rem;
                width: 0.86rem;
                max-width: 0.86rem;
                min-width: 0.86rem;
                box-sizing: border-box;
                height: 0.65rem;
                background-image: url("../img/item.png");
                background-size: cover;
                padding-left: 5px;
                padding-right: 5px;

                &.start {
                    font-size: 14px;
                    font-family: Helvetica-Bold, Helvetica;
                    font-weight: bold;
                    color: #b30b00;
                    background-image: url("../img/start.png");

                    &.disabled {
                        background-image: url("../img/start-disable.png");
                        color: #fff;
                    }
                }

                & > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 10px;
                    text-align: center;
                    font-family: Helvetica-Bold, Helvetica;
                    font-weight: bold;
                    line-height: 12px;
                    color: #003e34;
                }

                img {
                    margin-bottom: 0.05rem;
                    max-height: 0.38rem;
                    max-width: 0.36rem;
                    width: auto;
                    height: auto;
                }

                &.active {
                    background-image: url("../img/item-active.png");
                }
            }
        }
    }

    .duplicate-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 0.1rem;
        padding: 0 0.2rem;

        button {
            width: 1.6rem;
            height: 0.6rem;
            background-image: url("../img/duplicate.png");
            font-size: 10px;

            &.disabled {
                background-image: url("../img/duplicate-disable.png");

                .button-content {
                    color: #fff;
                }
            }

            .button-content {
                height: 0.6rem;
            }

            .btn-title {
                font-size: 14px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
            }
        }
    }

    .modal-wrapper {
        position: relative;
        width: 3rem;
        height: 4.7rem;
        background-image: url("../img/modal.png");
        background-size: 100% auto;
        background-repeat: no-repeat;

        .modal-content {
            position: absolute;
            top: 1.9rem;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            max-width: 1.6rem;
            height: 1.55rem;
            color: #fff;
            text-align: center;
            font-size: 14px;
            line-height: 17px;

            h3 {
                margin: 0;
                font-size: 18px;
                line-height: 22px;
                font-weight: bold;
                color: #fff;
            }

            .reward-list {
                margin-top: 0.2rem;
                display: flex;
                flex-direction: column;
            }

            button {
                width: 2.05rem;
                height: 0.42rem;
                font-size: 14px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                background-image: url("../img/confirm.png");

                .button-content {
                    height: 0.42rem;
                }
            }
        }
    }
}
</style>
